:root {
  --primary-clr: #dc1f5c;
  --white: #fff;
  --text-clr: #464646;
  --tabs-list-bg-clr: #f4f4f4;
  --btn-hvr: #dc1f5c;
}

body {
  color: var(--text-clr);
}

.flex_align_justify {
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper {
  min-height: 100vh;
  padding: 0 20px;
}

.tc_wrap {
  width: 80svw;
  max-width: 100%;
  height: 80svh;
  display: flex;
  border-radius: 3px;
  overflow: hidden;
  border-radius: 10px;
}
@media (max-width: 1025px) {
  .tc_wrap .tabs_content {
    width: 100%;
  }
}

.tc_wrap .tabs_list {
  width: 200px;
  background: var(--tabs-list-bg-clr);
  height: 80svh;
  color: #9b9b9b;
  overflow: auto;
}

.tc_wrap .tabs_content {
  width: 100svw;
  padding: 0 10px 0 20px;
  height: 100svh;
}
@media (min-width: 1025px) {
  .tc_wrap .tabs_content {
    width: calc(100% - 200px);
  }
}

.tc_wrap .tabs_content .tab_head,
.tc_wrap .tabs_content .tab_foot {
  color: var(--primary-clr);
  padding: 25px 0;
  height: 70px;
}

.tc_wrap .tabs_content .tab_head {
  text-align: center;
}

.tc_wrap .tabs_content .tab_body {
  height: calc(100% - 170px);
  overflow: auto;
}

.tc_wrap .tabs_list ul {
  padding: 70px 20px;
  text-align: left;
}

.tc_wrap .tabs_list ul li {
  padding: 10px 0;
  position: relative;
  margin-bottom: 3px;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.5s ease;
}

.tc_wrap .tabs_list ul li:before {
  content: '';
  position: absolute;
  top: 0;
  right: -20px;
  width: 2px;
  height: 100%;
  background: var(--primary-clr);
  opacity: 0;
  transition: all 0.5s ease;
}

.tc_wrap .tabs_list ul li.active,
.tc_wrap .tabs_list ul li:hover {
  color: var(--primary-clr);
}

.tc_wrap .tabs_list ul li.active:before {
  opacity: 1;
}

.tc_wrap .tabs_content .tab_body .tab_item h3 {
  padding-top: 10px;
  margin-bottom: 10px;
  color: var(--primary-clr);
}

.tc_wrap .tabs_content .tab_body .tab_item p {
  margin-bottom: 20px;
}

.tc_wrap .tabs_content .tab_body .tab_item.active {
  display: block !important;
}

.tc_wrap .tabs_content .tab_foot button {
  width: 125px;
  padding: 5px 10px;
  background: transparent;
  border: 1px solid;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.5s ease;
}

.tc_wrap .tabs_content .tab_foot button.decline {
  margin-right: 15px;
  border: none;
  border-color: #9b9b9b;
  background-color: #e6e5e5;
  color: #212121;
}

.tc_wrap .tabs_content .tab_foot button.agree {
  background: var(--primary-clr);
  border-color: var(--primary-clr);
  color: var(--white);
}

.tc_wrap .tabs_content .tab_foot button.decline:hover {
  background: var(--tabs-list-bg-clr);
  color: #212121;
}

.tc_wrap .tabs_content .tab_foot button.agree:hover {
  background: var(--btn-hvr);
  border-color: var(--btn-hvr);
}
